import './App.css';
import React, { useState, useEffect, useRef } from "react";
import { getPosts, getTags, getCategories, getFeaturedTagId, getPostsByCategory } from "./WordPressService"
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Filter from './Filter';
import Repeater from './Repeater';
import Highlighted from './Highlighted';
import Featured from './Featured';
import _ from "lodash";
import SearchBlog from './SearchBlog';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
  },
}));

function App(props) {
  const classes = useStyles()
  const [posts, setPosts] = useState([]);
  const [featuredPosts, setFeaturedPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [sidebar] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedTags, setSelectedTags] = useState({});
  const [paramTags, setParamTags] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(null);
  const [postsUrl, setPostsUrl] = useState(props.url.includes("categories") ? `${props.url}&_embed&per_page=${props.perPage}`: (props.url.includes("tags") ? `${props.url}&_embed&per_page=${props.perPage}` : `${props.url}posts?_embed&per_page=${props.perPage}`));
  const [markup] = useState(props.markup);
  const [loading, setLoading] = useState(false);
  const [highlightedPosts, setHighlightedPosts] = useState([]);
  const [highlighted, setHighlighted] = useState(0);
  const [htmlPosts, setHtmlPosts] = useState([]);
  const [featuredHtmlPosts, setFeaturedHtmlPosts] = useState([]);
  const [hiddenRightColumn, setHiddenRightColumn] = useState(false);
  const [rightColumnPosts, setRightColumnPosts] = useState(undefined);
  const [blogSearch] = useState(props.blogSearch);
  const [displayTags, setDisplayTags] = useState(true);
  const [showTop, setShowTop] = useState(false);
  const [queryValue, setQueryValue] = useState("");

  useEffect(() => {
    const newURL = new URL(window.location.href);
    const searchValueQuery = newURL.searchParams.get('search');

    if(searchValueQuery){
      setQueryValue(searchValueQuery);
    }
  },[]);

  useEffect(() => {
    const url = props.url.substring(0,props.url.indexOf('/v2/') + 4);
    getCategories(url).then(data => {
      setCategories(data);
    }).catch(err => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    if (props.url.includes("categories")){
      let url = `${props.url}&_embed&per_page=100`
      getPostsByCategory(`${url}&page=1`).then(data => {
        let results = Array.from(data.data.map((post) => {
          if(post["_embedded"]["wp:term"][1][0]){
            return ({ id: post["_embedded"]["wp:term"][1][0]["id"], name: post["_embedded"]["wp:term"][1][0]["name"] });
          }
        }));
        results = results.filter(function( post ) {
          return post !== undefined;
        });
        let resultsUniq = [...new Map(results.map(oValue => [oValue.id, oValue])).values()]
        resultsUniq = resultsUniq.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        setTags(resultsUniq);
      })
    } else {
      if (props.url.includes("tags")){
        setDisplayTags(false);
      }
      const url = props.url.substring(0,props.url.indexOf('/v2/') + 4);      
      getTags(url).then(data => {
        setTags(data);
      }).catch(err => {
        console.log(err);
      });
    }    
  }, []);

  useEffect(() => {
    const trueKeys = Object.entries(selectedTags)
      .filter(([key, value]) => value)
      .map(([key, value]) => key)
      .join(',');
    let newPostsUrl = postsUrl;
    const urls = postsUrl.split('&');
    console.log('trueKeys, page: ',  trueKeys, page);
    const filterparam = trueKeys ? `tags=${ trueKeys}` : '';  
    const index = urls.findIndex((url) => url.includes('page') && !url.includes('per_page'));

    if (index > -1) {
      urls[index] = page ? `page=${page}` : 'page=1';
      newPostsUrl = urls.join('&');
    } else {
      newPostsUrl = page
        ? urls.join('&') + `&page=${page}${filterparam}`
        : urls.join('&') + `&page=1${filterparam}`;
    }

    setPostsUrl(newPostsUrl);
    setLoading(true);
  
    if (trueKeys ) {
      newPostsUrl = urls.join('&') + '&tags=' + (trueKeys);
    }
  
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
  
    debounceTimeout.current = setTimeout(() => {
      getPosts(`${newPostsUrl}${queryValue && `&search=${queryValue}`}`).then(posts => {
        setPosts(posts.data);
        setTotalPages(parseInt(posts.totalPages));
        setLoading(false);
      });
    }, 300); // Adjust the debounce delay as needed
  
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [selectedTags, page, paramTags, postsUrl]);
  const debounceTimeout = useRef(null);
  useEffect(() => {
    setHtmlPosts(buildMarkup());
  }, [posts])

  useEffect(() => {
    buildFeaturedUrlAndFetchPosts();
  }, []);
  
  useEffect(() => {
    if(featuredPosts !== undefined){
      setFeaturedHtmlPosts(buildFeaturedMarkup())
    }
  }, [featuredPosts])

  const toggleTags = (event) => {
    const updatedTags = { ...selectedTags, [event.target.value]: event.target.checked };
    setSelectedTags(updatedTags);
    let tags = [];
    for (const [key, value] of Object.entries(updatedTags)) {
      if (value) {
        tags.push(key);
      }
    }

    var newPostsUrl = '';
    var urls = postsUrl.split('&');
    var index = urls.findIndex((url) => findString(url, 'tags'));
    if (tags.length > 0) {
      if (index > -1) {
        urls[index] = 'tags=' + tags.join(',');
        newPostsUrl = urls.join('&');
      } else {
        newPostsUrl = urls.join('&') + '&tags=' + tags.join(',');
      }
      setPostsUrl(newPostsUrl);
    } else {
      if (index > -1) {
        urls.splice(index);
        newPostsUrl = urls.join('&');
      } else {
        newPostsUrl = postsUrl;
      }
      setPostsUrl(newPostsUrl);
    }
    setPage(1);
  };

  function findString(url, str) {
    return url.includes(str);
  }

  const handleChangePage = (event, value) => {
    setPage(value);
    const newURL = new URL(window.location.href);
    newURL.searchParams.set('page', value);
    window.history.pushState({ path: newURL.href }, '', newURL.href);
  };

  function getTimestamp(jsonDate) {
    let date = new Date(jsonDate);

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    let month = monthNames[date.getMonth()];

    let timestamp = month + " " + date.getDate() + ", " + date.getFullYear();
    return timestamp;
}



  function buildFeaturedUrlAndFetchPosts() {
    const url = props.url.substring(0,props.url.indexOf('/v2/') + 4);
    let category = ""
    if (props.url.includes("categories")){
      category = `&categories=${props.url.substring(props.url.indexOf("categories=")+11, props.url.length)}`
    }
    getFeaturedTagId(url)
    .then(res => {
      if(res[0] !== undefined){
        getPosts(url + 'posts?tags=' + res[0] + category +'&_embed&order=desc').then(posts => {
          setFeaturedPosts(posts.data);
        });
      }
      }).catch(err => {console.log(err)});
  }

  function buildMarkup() {
    if (posts.length > 0) {
      var commonPosts = posts;
      
      return replaceHtml(commonPosts);
    }
  }

  function buildFeaturedMarkup(){
    if(featuredPosts !== undefined && featuredPosts.length > 0) {
      var htmlPosts = featuredPosts.map((row) => {
        if(props.featuredMarkup !== "" && props.featuredMarkup !== undefined){
          return replaceFeaturedMarkupPlaceholders(row);
        } else {
          return `<div class='card d-flex flex-row'>
          <div class='blog-card-div-img-template-2'>
            <a href='/blogs/${row.slug}' target='_blank'><img src='${row._embedded['wp:featuredmedia'][0]['source_url']}' class='img-fluid blog-card-div-img-template-2'></a>
          </div>
          <div class='card-body card-body-template-2'>
            <a href='/blogs/${row.slug}' target='_blank'></a>
            <h6 class='card-title h6-template-2'>${row.title.rendered}</h6>
            <small>By ${row._embedded.author[0].name}</small> 
          </div>
          </div>
          `
        }
      });
      return htmlPosts;
    }
  }

  function getKeyVals (object, str = "") {
    if (typeof object !== typeof Object())
        return [
            [str, object]
        ];

    let keyVals = [];

    for (const key in object) {
        keyVals = keyVals.concat(getKeyVals(object[key], (str.length <= 1 ? key : `${str}.${key}`))); // recursive ( ͡~ ͜ʖ ͡°)
    }

    return keyVals;
  }
  const searchInput = (inputVal) => {
    const postUrl = props.url.includes("categories") ?`${props.url}&search=${inputVal.toLowerCase()}&_embed&per_page=${props.perPage}`:`${props.url}posts?search=${inputVal.toLowerCase()}&_embed&per_page=${props.perPage}`    
    getPosts(postUrl)
        .then((res) => {            
          setPosts(res.data);
          setTotalPages(parseInt(res.totalPages));
          setLoading(false);                          
        })
        .catch((error) => console.log(error));
  };
  function replaceHtml(posts){
    var htmlPosts = posts.map((row) => {
      let generatedMarkup = markup;
      // replacing multitag token
      const tagleng = row['_embedded']['wp:term']['1'].length === 0 ? 1 : row['_embedded']['wp:term']['1'].length ;
      let tagString = '';
      for (let i = 0; i < tagleng; i++) {
        tagString += `<div class='category-type'>{{_embedded.wp:term.1.${i}.name}}</div>`;
      }
      generatedMarkup = generatedMarkup.replace('{{tags}}', tagString);

      getKeyVals(row).forEach(val => {
        if (val[0].includes("date")) {
          val[1] = getTimestamp(val[1]);
        }
        generatedMarkup = generatedMarkup.replace(new RegExp(`{{${val[0]}}}`, 'g'), val[1]); 
      });

      // Executing this line because no tags are present in the post. Take into account that 0.0 is for categories and 1.0 is for tags
      generatedMarkup = generatedMarkup.replace(new RegExp(`{{_embedded.wp:term.1.0.name}}`, 'g'), "Uncategorized"); 
     
      return generatedMarkup;
    });

    return { __html: htmlPosts.join(' ') };
  }

  function replaceFeaturedMarkupPlaceholders(row){
    var featuredMarkup = props.featuredMarkup;;
    getKeyVals(row).forEach(val => {
      if (val[0].includes("date")) {
        val[1] = getTimestamp(val[1]);
      }
      featuredMarkup = featuredMarkup.replace(new RegExp(`{{${val[0]}}}`, 'g'), val[1]);
    })

    return featuredMarkup;
  }

  function buildEngageForm () {
    const form = document.getElementById("blogFormScripts");
    if(form.innerHTML != ""){
      let formHTML = form.innerHTML;
      document.getElementById("blogFormScripts").style.display = "none";
      return formHTML;
    } else {
      return "<b> Missing formstack form or error during loading formstack form. </b>";
    }
  }
  const params = new URLSearchParams(window.location.search);
  const pageValue = parseInt(params?.get("page"));
  //
  useEffect(() => { 
    page ? setPage(page) : pageValue ? setPage(pageValue) : setPage(1);
  }, [pageValue, page]);
  useEffect(() => {
   if (showTop) {
    const targetDiv = document.getElementById(props.blogElementIndexId);
    if (targetDiv && page ===1) {
            window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else  {
      targetDiv.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
      }
  }
  }, [props.blogElementIndexId, showTop, page])
  
  return (
    <div id={props.blogElementIndexId} className="row blog-index-page">
      {props.blogSearch === "true" && <SearchBlog queryValue={queryValue} setQueryValue={setQueryValue} searchInput={searchInput} />}
      {props.sidebar !== undefined &&
      props.sidebar !== "false" &&
      props.sidebar !== false ? (
        <div className="blog-index-main">
          {page !== null && htmlPosts !== undefined && (
            <Repeater
              setShowTop={setShowTop}
              posts={posts}
              htmlPosts={htmlPosts}
              setPage={setPage}
              page={page}
              totalPages={totalPages}
              loading={loading}
              handleChangePage={handleChangePage}
            />
          )}
        </div>
      ) : (
        <div className="blog-index-main">
          {page && htmlPosts !== undefined && (
            <Repeater
              setShowTop={setShowTop}
              posts={posts}
              htmlPosts={htmlPosts}
              setPage={setPage}
              page={page}
              totalPages={totalPages}
              loading={loading}
              handleChangePage={handleChangePage}
            />
          )}
        </div>
      )}

      {props.sidebar !== undefined &&
        props.sidebar !== "false" &&
        props.sidebar !== false && (
          <div className="d-none d-lg-block blog-index-sidebar">
            {featuredHtmlPosts !== undefined && (
              <Featured posts={featuredHtmlPosts} name={props.featuredTitle} />
            )}
            {props.formToken !== undefined &&
              props.formToken !== "" &&
              props.formToken !== "null" && (
                <div
                  className="blog-index-form"
                  dangerouslySetInnerHTML={{ __html: buildEngageForm() }}
                />
              )}
            {displayTags && (
              <Filter
                filters={tags}
                name="Filters"
                selectedCategories={selectedTags}
                setSelectedCategories={setSelectedTags}
                toggleFilter={toggleTags}
                setParamTags={setParamTags}
                page={page}
              />
            )}
          </div>
        )}
    </div>
  );
}

export default App;
