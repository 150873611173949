import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const indexElement = document.getElementById('blogIndex');


ReactDOM.render(
  <React.StrictMode>
    <App
    url={indexElement.getAttribute('url')}  
    layout={indexElement.getAttribute('layout')} 
    blogElementIndexId={indexElement.getAttribute('blogElementIndexId')}
    displayCategories={indexElement.getAttribute('displayCategories')}  
    displayTags={indexElement.getAttribute('displayTags')} 
    perPage={indexElement.getAttribute('perPage')}
    featuredTitle={indexElement.getAttribute('featuredTitle')} 
    formToken={indexElement.getAttribute('formToken')}
    sidebar={indexElement.getAttribute('sidebar')}
    blogSearch={indexElement.getAttribute('blogSearch')}
    //markup={`<div class=\"col-md-4 mb-5 padding-top-50\">\n\t<div class=\"card d-flex\">\n\t\t<blog>\n\t\t\t<a href=\"{{href}}\" target=\"_blank\">\n\t\t\t\t<img src=\"{{_embedded.wp:featuredmedia.0.source_url}}\" class=\"img-fluid\">\n\t\t\t</a>\n\t\t\t<div class=\"card-body\">\n\t\t\t\t<a href=\"{{href}}\" target=\"_blank\">\n\t\t\t\t\t<h6 class=\"card-title\">{{title.rendered}}</h6>\n\t\t\t\t</a>\n\t\t\t\t<small>By {{_embedded.author.0.name}}</small>\n\t\t\t\t<br>\n\t\t\t\t<p>{{excerpt.rendered}}</p>\n\t\t\t</div>\n\t\t\t<div class=\"card-footer\" style=\"transform: translate3d(0, 0, 5px);\">\n\t\t\t\t<a href=\"{{href}}\" target=\"_blank\">\n\t\t\t\t\t<small>CONTINUE READING &gt;</small>\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t</blog>\n\t</div>\n</div>`}
    markup={indexElement.getAttribute('markup')}
    featuredMarkup={indexElement.getAttribute('featuredMarkup')}
    />
  </React.StrictMode>,
  document.getElementById('blogIndex')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
