import { useEffect, useState } from "react";

function SearchBlog({ searchInput, setQueryValue, queryValue }) {
  const [searchValue, setSearchValue] = useState("");
  
  useEffect(()=>{
    setSearchValue(queryValue);
    searchInput(queryValue);
  },[queryValue]);

  const onKeyDownSearchInput = (e) =>{
    const { target: { value } } = e;
    if (e.key === 'Enter') {
      setQueryValue(value);
      if(value){
        const newURL = new URL(window.location.href);
        newURL.searchParams.set('search', value);
        newURL.searchParams.set('page', '1');
        window.history.pushState({ path: newURL.href }, '', newURL.href);
      }else{
        const newURL = new URL(window.location.href);
        newURL.searchParams.delete('search');
        newURL.searchParams.set('page', '1');
        window.history.pushState({ path: newURL.href }, '', newURL.href);
        searchInput('');
      }
    }
  }

  const buttonClickEvent = () =>{
    setQueryValue(searchValue);
    if(searchValue){
        const newURL = new URL(window.location.href);
        newURL.searchParams.set('search', searchValue);
        window.history.pushState({ path: newURL.href }, '', newURL.href);
      }else{
        const newURL = new URL(window.location.href);
        newURL.searchParams.delete('search');
        window.history.pushState({ path: newURL.href }, '', newURL.href);
        searchInput('');
      }
  };
  
  const onChangeHandler = (value) =>{
    setSearchValue(value);
  }

  return (
    <div
      className="search-container"
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
        borderRadius: "6px",
        border: "1.5px solid #acadac",
      }}
    >
      <input
        type="text"
        onKeyDown={(e) => onKeyDownSearchInput(e)}
        onChange={(e) => onChangeHandler(e.target.value)}
        style={{
          fontSize: "22px",
          fontWeight: "300",
          fontFamily:"Arial, 'FontAwesome'",
          padding: "31px",
          paddingBottom: " 32px",
          borderRadius: "6px 0 0 6px",
        }}
        className="form-control search-input"
        placeholder="&#xF002; Search by title or keyword"
        value={searchValue}
      />
      <button
        onClick={() => buttonClickEvent()}
        style={{
          textAlign: "center",
          letterSpacing: "0.2rem",
          width: "250px",
          fontSize: " 30px",
          padding: "8px",
          background: "#fc6b03",
          border: "2px solid #fc6b03",
          borderRadius: "0 5px 5px 0",
        }}
      >
        Search
      </button>
    </div>
  );
}

export default SearchBlog;
