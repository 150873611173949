import React, { Fragment, useEffect } from "react";
import { Checkbox, InputLabel, withStyles } from '@material-ui/core';

const CustomCheckboxColor = withStyles({
  root: {
    color: "rgba(0, 0, 0, 0.54)",
    "&$checked": {
      color: "inherit"
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

function Filter(props) {
  const { selectedCategories } = props;
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const filterParams = params.get('filter');
    props.setParamTags(filterParams?.split('&').join(',') || '');
    const initialSelectedCategories = {};

    if (filterParams) {
      filterParams.split('&').forEach(id => {
        initialSelectedCategories[id] = true;
      });
    }

    props.setSelectedCategories(initialSelectedCategories);
  }, [props.setSelectedCategories]);

  const handleCheckboxChange = (event) => {
    const params = new URLSearchParams(window.location.search);
    props.toggleFilter(event);
    const updatedTags = { ...selectedCategories, [event.target.value]: event.target.checked };
    const trueKeys = Object.entries(updatedTags)
      .filter(([key, value]) => value)
      .map(([key, value]) => key)
      .join('&');
    params.set('page', '1');
    if (trueKeys) { 
        params.set('filter', trueKeys);
      } else {
        params.delete('filter');
      }
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState(null, '', newUrl);
  };

  return (
    props.filters.length >= 1 &&
      <>
        <div className="blog-index-filters">
          <InputLabel className="blog-index-filters-title" color="secondary"><b>{props.name}</b></InputLabel>
          {props.filters.map((filter) => {
            const isChecked = !!props.selectedCategories[filter.id]; // Ensure isChecked is always a boolean
            return <div key={filter.id}>
              <CustomCheckboxColor
                value={filter.id}
                onChange={handleCheckboxChange}
                checked={isChecked}
              />
              <span>{htmlDecode(filter.name)}</span>
            </div>
          })}
        </div>
        <br />
      </>
  );
}

function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export default Filter;