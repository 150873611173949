import React, { useEffect, useRef } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import ContentLoader from './ContentLoader';
import NotFound from './NotFound';
import Link from './components/Link';

function Repeater(props) {
  const contentDivRef = useRef(null);
  useEffect(() => {
    const handleURLChange = () => {
      const params = new URLSearchParams(window.location.search);
      const pageFromURL = parseInt(params.get('page'));
      if (!isNaN(pageFromURL) && pageFromURL !== props.page) {
        props.setPage(pageFromURL);
      }
    };
    handleURLChange();
  }, [props, props.page]);

  useEffect(() => {
    if (props?.page !== 1 || contentDivRef.current) {
      props.setShowTop(true);
    }
  }, [props, props?.page]);
  const preventDefault = (event) => event.preventDefault();

  return !props.loading ? (
    props.page && props.posts.length > 0 ? (
      <div id={props.page} ref={contentDivRef}>
        <div
          className="row blog-index-posts"
          dangerouslySetInnerHTML={props.htmlPosts}
        />
        {props.posts.length > 0 && props.totalPages > 1 && (
          <div
            className="blog-index-paginator"
            style={{
              alignItems: 'center',
              display: 'flex',
              borderRadius: '16px',
              justifyContent: 'center',
            }}
          >
            <span>Page: </span>
            <Pagination
              count={props.totalPages}
              page={props.page}
              onChange={props.handleChangePage}
              shape="rounded"
              renderItem={(item) => (
                <PaginationItem
                  href={`?page=${item.page}`}
                  component={Link}
                  onChange={props.handleChangePage}
                  {...item}
                />
              )}
            />
          </div>
        )}
      </div>
    ) : (
      <NotFound />
    )
  ) : (
    <ContentLoader />
  );
}

export default Repeater;
