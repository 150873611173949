import React from 'react';
import { Link as LinkMaterial } from '@material-ui/core';

const Link = (props) => {
  const { href, onChange, className } = props;

  const url = new URL(window.location.origin + href);
  const params = new URLSearchParams(url.search);
  const page = params.get('page');

  return (
    <LinkMaterial
      {...props}
      className={className}
      onClick={(e) => {
        e.preventDefault();
        onChange(e, page);
      }}
    />
  );
};

export default Link;
