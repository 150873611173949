/**
 * Content loader
 */
 import React from 'react';
 import CircularProgress from '@material-ui/core/CircularProgress';
 
 const ContentLoader = () => (
    <div class="blog-index-main">
      <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100vh", color: "black", minHeight: "100vh"}}>
         <CircularProgress  color="inherit"/>
      </div>
    </div>
 );
 
 export default ContentLoader;